import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { surveyType } from 'src/app/shared/constants/shared.constants';
import { RelationshipFilterService } from 'src/app/shared/services/filter-relationship.service';
import { SurveySubCategory } from 'src/app/shared/constants/survey.sub.category';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistDashbaordService {

  constructor(private http: HttpClient, private filterService: RelationshipFilterService, private sharedService: SharedService,) {

  }
  private serviceUrl = this.sharedService.configuration.baseUrl;
  response: any;
  getInfluenceAllCustomersSearch(params) {
    var url = environment.baseUrlDashboard + "/Dashboard/GetInfluenceAllCustomersSearch/" + SurveySubCategory.Distributor + "?FilterSearch=" + params;
     return this.http.post(url, this.filterService.filterObj);
   }

  getLoyaltyShiftDetails() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetLoyaltyShiftDetails/' + SurveySubCategory.Distributor, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res
      });
  }
  getNpsAndAvgRating() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetNPS/' + SurveySubCategory.Distributor, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        this.response = res.Response;
        return this.response;
      }).catch(e => {

      });
  }

  getNpsRating() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetNPSRating/' + SurveySubCategory.Distributor, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        this.response = res.Response;
        return this.response;
      }).catch(e => {

      });
  }

  getResponseRate() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetResponseRate/' + SurveySubCategory.Distributor, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getResponserateTopHeader(){
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetResponserateTopHeader/Distributor', this.filterService.filterObj)
    .toPromise()
    .then((res: any) => {
      return res;
    });
  }
  //Need to change with
  getInvitations() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetResponseRate', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res.Response;
      });
  }

  //************should be removed*****************//
  getNpsByTouchPoint() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetNPSByTouchpoint', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getCustomerFeedback() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetCustomerFeedback/'+ SurveySubCategory.Distributor, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
           return res;
      });
  }

  //************should be removed*****************//
  getTop5customers() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTopCustomerByTransaction', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  //For Survey instances
  testAPIOfSurveyInstances(params) {
    let {pageSize, pageNo, sortField, sortType, StatusFlag, queryString} = params;
    var url = environment.baseUrlDashboard + "/Dashboard/GetSurveyInstances/" + SurveySubCategory.Distributor 
    + "?pageNo=" + (pageNo ? pageNo : 0)
    + "&pageSize=" + (pageSize ? pageSize : 0) 
    + "&sortField=" + (sortField ? sortField : "") 
    + "&sortType=" + (sortType ? sortType : "")
    + "&StatusFlag=" + (StatusFlag ? StatusFlag : "")
    + "&instanceName=" + (queryString ? queryString : "");
    return this.http.post(url, this.filterService.filterObj)
      .toPromise()
      .then((data: any) => {
        return data;
      });
  }
  /**
   * Added by Rashmi
   * for Top 3 and Top 10
   */
  getTop3Questions() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTopThreeQuestions/' + SurveySubCategory.Distributor, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getTop3Categories(){
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTotalResponseSummaryQuestionCategory', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getTop3DetailQuestions(pageNo,pageSize) {
    //  return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTotalRespSummaryByTPQuestionCategory?questionResponseCategoryId='+id, this.filterService.filterObj)
    // .toPromise()
    // .then((res: any) => {
    //   return res;
    // });

    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetAllOptionQuestions/'+ SurveySubCategory.Distributor+"?pageNo="+pageNo+"&pageSize="+pageSize , this.filterService.filterObj)
    .toPromise()
    .then((res: any) => {
      return res;
    });
  }

  getAllFreeTextQuestions(pageNo,pageSize,questionID,optionID, parentQuestionId, parentAnsOptionID){
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetAllFreeTextQuestions/'+ SurveySubCategory.Distributor
      + "?pageNo=" + (pageNo ? pageNo : 0)
      + "&pageSize=" + (pageSize ? pageSize : 0) 
      + "&questionId=" + (questionID ? questionID : "") 
      + "&optionId=" + (optionID ? optionID : "")
      + "&parentQuestionId=" + (parentQuestionId ? parentQuestionId : "")
      + "&parentOptionId=" + (parentAnsOptionID ? parentAnsOptionID : "")
      , this.filterService.filterObj
    )
    .toPromise()
    .then((res: any) => {
      return res;
    });
  }
  getTop10distributorsRevenue(){
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTopTenByRevenue/Distributor', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getTop10distributors() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTopTenByResponses/Distributor', this.filterService.filterObj)
    //return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTopCustomerByTransaction', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        console.log("top 10")
        return res;
      });
  }
  getQuestionByCategory() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTotalResponseSummaryQuestionCategory', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getQuestionByTouchPoint() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTotalResponseSummaryByTouchPoint', this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getQuestionByCatId(id) {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTotalRespSummaryByTPQuestionCategory?questionResponseCategoryId=' + id, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getQuestionsByTP(id, pageNo = 1, pageSize = 10) { //touchPointID=6
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTotalRespSummaryByTPQuestions?touchPointID=' + id
      + "&pageNo=" + pageNo
      + "&pageSize=" + pageSize, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getStoryBoard() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetStoryBoard/' + SurveySubCategory.Distributor , this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }
  getStoryBoardDetails(id) {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/storyboard/Distributor/category/' + id, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  //************should be rename*****************//
  getCustomers(topByTransactionCount) {
    //return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetAllCustomers?TopByTransactionCount=50', this.filterService.filterObj)
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetAllCustomers/'+ SurveySubCategory.Distributor+'?TopByTransactionCount=' + topByTransactionCount, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  //************should be rename*****************//
  getCustomerDetailsById(CustomerName) {
     return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetInfluencerDetailByCustomer/"+ SurveySubCategory.Distributor+"?CustomerName=" + CustomerName, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getInfluencerContacts(params) {
    return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetInfluencerContacts/"+ SurveySubCategory.Distributor+"?CustomerName=" + params.CustomerName
      + "&InfluencerType=" + params.InfluencerType
      + "&PNDFlag=" + params.PNDFlag
      + "&PageSize=" + params.PageSize
      + "&PageIndex=" + params.PageIndex
      , this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
          return res;
      });
  }

  getLoyaltyShiftContactDetails(sortField, sortType, pndFrom, pndTo, pageNo, pageSize) {
    var url = environment.baseUrlDashboard + "/Dashboard/GetLoyaltyShiftContactDetails/"+ SurveySubCategory.Distributor+"?sortField=" + sortField + "&sortType=" + sortType + "&pndFrom=" + pndFrom + "&pndTo=" + pndTo + "&pageNo=" + pageNo + "&pageSize=" + pageSize;
    return this.http.post(url, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  getGetWidgetLocation() {
    return this.http.get(environment.baseUrlDashboard + "/Dashboard/GetWidgetLocation/DIST?" + Date.now() + Math.random())
      .toPromise()
      .then((res: any) => res.Response)
      .then(data => {
        return data;
      });
  }

  postAddUpdateWidgetLocation(widjetorderListToSend) {
     return this.http.post(environment.baseUrlDashboard + "/Dashboard/AddUpdateWidgetLocation/DIST", widjetorderListToSend)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }


  getRelationalSurveyList(surveyName) {
    console.log(surveyName)
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetRelationalSurveyList/'+ SurveySubCategory.Distributor+"?surveyName=''", this.filterService.filterObj)
    .toPromise()
    .then((res: any) => {
      return res;
    });
  }
  getAdvancedSearchStatus(){
    return this.http.get(environment.baseUrlDashboard + "/Dashboard/GetAdvancedSearchStatus/"+ SurveySubCategory.Distributor)
    .toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    });
  }

  getAdvancedSearchCategory(){
    return this.http.get(environment.baseUrlDashboard + "/Dashboard/GetAdvancedSearchCategory/"+ SurveySubCategory.Distributor)
    .toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    });

  }

  searchSurvey(searchCriteria,SearchFlag){
    return this.http.post(environment.baseUrlDashboard + "/Dashboard/SearchSurvey/"+ SurveySubCategory.Distributor +"?SearchFlag="+SearchFlag, searchCriteria)
    .toPromise()
    .then((res: any) => {
     return res;
    });
  }

  getRecentlySearchedSurveys (){
    return this.http.get(environment.baseUrlDashboard + "/Dashboard/GetRecentlySearchedSurveys/"+SurveySubCategory.Distributor)
    .toPromise()
    .then((res: any) => res)
    .then(data => {
      console.log(data)
      return data;
    });

  }
  MarkDefaultSurvey(surveyID,isAll){
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/MarkDefaultSurvey/Distributor?surveyID='+surveyID+'&ForAllUsers='+isAll,null)
    .toPromise()
    .then((res: any) => {
      return res;
    }).catch(e => {
console.log(e)
    });
  }
  DeleteDefaultSurvey(surveyID,isAll){
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/DeleteDefaultSurvey/'+ SurveySubCategory.Distributor+'?ForAllUsers='+isAll,null)
    .toPromise()
    .then((res: any) => {
      return res;
    });
  }
  SaveRecentSearch(searchCriteria){
    console.log(searchCriteria);
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/SaveRecentSearch/'+ SurveySubCategory.Distributor, searchCriteria)
    .toPromise()
    .then((res: any) => {
      return res;
    });
  }

  getSurveyInstances(params){
    let {pageSize, pageNo, sortField, sortType, StatusFlag, queryString} = params;
    var url = environment.baseUrlDashboard + "/Dashboard/GetSurveyInstances/" + SurveySubCategory.Distributor 
    + "?pageNo=" + (pageNo ? pageNo : 0)
    + "&pageSize=" + (pageSize ? pageSize : 0) 
    + "&sortField=" + (sortField ? sortField : "") 
    + "&sortType=" + (sortType ? sortType : "")
    + "&StatusFlag=" + (StatusFlag ? StatusFlag : "")
    + "&instanceName=" + (queryString ? queryString : "");
    return this.http.post(url, this.filterService.filterObj)
      .toPromise()
      .then((data: any) => {
        return data;
      });
  }
 
 getNPSByCountriesDetails(){
    return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetNPSByCountries/Distributor", this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch(e => {
      });
  }

  exportExcelReport(parameter) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/ExportToExcel/' + SurveySubCategory.Distributor, parameter, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getRelationshipStoryBoardDetails(id){
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/storyboard/Distributor/category/'+id+'/' + SurveySubCategory.Distributor, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  getNPSResponseList(sortField,sortType,page, size) {
    return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetNPSRespondedList/" + SurveySubCategory.Distributor + "?sortField=" + sortField + "&sortType=" + sortType + "&pageNo=" + page + "&pageSize=" + size, this.filterService.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  } 
}

